<template>
  <footer class="footer">
    <div class="div-footer-container">
      <div class="div-reserved-incognito">© 2021, incognito. all rights reserved.</div>
      <div class="div-routers-footer d-flex">
        <router-link to="/" class="item-navbar m-3">HOME</router-link>        
        <a href="https://whitepaper.incognitonft.com/" class="item-navbar m-3" target="_blank">WHITEPAPER</a>
        <router-link to="/rewards" class="item-navbar m-3">REWARDS</router-link>
        <router-link to="/gallery" class="item-navbar m-3">GALLERY</router-link>
        <div class="item-navbar m-3"><a href="/#roadmap" style="text-decoration: none;color: inherit;">ROADMAP</a></div>
      
        <a href="https://twitter.com/IncognitoNFT" target="_blank" class="social-links"><i class="fab fa-twitter social-icon"></i></a>
        <a href="https://discord.gg/njnzd4gZwx" target="_blank" class="social-links"><i class="fab fa-discord social-icon"></i></a>
        <a href="https://www.instagram.com/incognitonft" target="_blank" class="social-links"><i class="fab fa-instagram social-icon"></i></a>
      </div>

    </div>
  </footer>
</template>

<style scoped>
.topbar-link{
  font-weight: 700;
  opacity: 0.5;
}
.topbar-link:hover{
  opacity: 1!important;
  color: #FFF!important
}
.separator{
  position: absolute;
  top: 38%;
}
.router-link-active{
  opacity: 1!important
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 90px;
  background-color: #080e1f;
  color: #7eb5f3;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.gallery-tooltip{
  left: -15px!important;
  top: -5px!important;
}
</style>
