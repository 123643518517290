@font-face {
  font-family: ProximaNova;
  src: url(./font/proxima-nova.otf);
}
.w-35{width: 35%!important;}
.w-40{width: 40%!important;}
.w-45{width: 45%!important;}
.w-55{width: 55%!important;}
.w-60{width: 60%!important;}
.w-80{width: 80%!important;}
.fontProximaNova{
  font-family: 'ProximaNova'!important;
}
body {
  color: #f4f3ef;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  color: #66615b;
  font-size: 14px;
  background-color: #f4f3ef;
  font-family: 'ProximaNova'
}
/* NAVBAR */
.nav-navbar-content{
  background-color: #080E1F!important;
  flex-direction: column;
  padding-top: 0px!important;
}
.div-navbar-padding{
  padding-top: 10px;
}
.div-navbar-items{
  width: 70%;
}
.separator-navbar{
  opacity: 50%;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}

.item-navbar{
  opacity: 50%;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  font-family: 'ProximaNova';
  text-decoration: none;
}

.item-navbar:hover{
  opacity: 1;
  cursor: pointer;
}

.div-navbar-items div{
  font-weight: bold;
}

/* ----------------------------- */

/* GO INCOGNITO PAGE */

/* Incognito */
// .go-incognito-backgroud{
//   position: absolute;
//   width: 2446px;
//   height: 619px;
//   left: 1280px;
//   top: 0px;
  
//   background: url(incognito-logo.png);
//   opacity: 0.03;
//   transform: rotate(90deg);
// }


.welcome-title{
  color: #FFF;  
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 9rem;
  margin-bottom: 7rem;
}

.profile-image-container{
  width: 163px;
  height: 163px;
  border-radius: 50%;
  background-color: #FFF;
}

.username {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFF;
}

.verified{
  font-size: 16px;
  line-height: 19px;
  color: #FFF
}
.members-modal-container{
  overflow:scroll; 
  overflow-x:hidden;
  max-height: 95vh
}

.members-modal-titles{
  font-weight: 700;
}

.your-icons{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FF8E3D;
}

.icons-divider{
  height: 1px;
  border: 1px solid #92AFFF
}

.icons-separator-container{
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.sort-by{
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFF
}

.sort-method{
  width: 219px;
  background: #121E3A;
  height: 35px;
  border: 1px solid #2D4783;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.show-icons-text{
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #92AFFF;
}
.user-icons{
  position: relative;
  width: 242px;
  height: 275px;
}
.user-icons img{
  cursor: pointer;
}
.nft-image{
  position: absolute;
  top: 20px;
  left: 0px;
}
.nft-name{
  margin-top: 60px;
}
.go-incogntio-row-content{
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
}
.div-row-nft-cards{
  padding-right: 110px; 
  padding-left: 110px;
  margin-bottom: 10rem;
}
.div-row-nft-cards .card{
  background: linear-gradient(180deg, #121E3A 55%, transparent 45%);
  box-shadow: none!important;
}
/* ------------------- */
/* ------------HOME-1----------------- */

.modal-members-details{
  background: #1C2847;
}
.modal-member-profile-picture{
  border-radius: 0px!important;
}

.div-page-content{
  background:#0A1329;
}
.div-home-page-content{
  background-image: url(./img/Background-1.jpg);
  background-position: center;
}
.div-home-page-content-1{
  padding-top: 30px;
  height: 85vh;
}
.div-home-img-2 img{
  width: 380px;
}
.div-home-text-3{
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #FFFFFF;
  font-family: 'ProximaNova';
  width: 500px;

}
.div-home-content-1{
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-bottom: 15%;
}
.div-img-home{
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-img-home img{
  width: 80%;
  padding-top: 5%;
}
.container-amount-ntf {
  display: flex;
  background-color: #e4e4e4;
  width: 250px;
  height: 42px;
  padding: 11px 18px;
  border-radius: 50px;
  justify-content: space-between;
  align-items: center;
  background-color: #252E45;
}
.icon-ETH{
  font-size: 18px;
}
.btn-ETH{
  height: 42px;
  width: 200px;
  background: linear-gradient(180deg, #FF6B00 0%, #FFAF75 128.61%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 12px 15px rgba(255, 142, 61, 0.15);
  border-radius: 27px;

  font-size: 20px;
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
}
.container-mint-buttons {
  padding: 0;
  background: none;
  border: none;
}

.viewInOpenSea{
  padding: 18px 16px;
  height: 42px;
  width: 200px;
  background: linear-gradient(180deg, #FF6B00 0%, #FFAF75 128.61%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 12px 15px rgba(255, 142, 61, 0.15);
  border-radius: 27px;
  font-size: 16px;
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: normal;
  color: #FFF;
  text-decoration: none;
}
.viewInOpenSea:hover{
  color: #FFF;
}
.viewInOpenSea:focus{
  display: inline;
}

.scroll-content{
  scrollbar-width: none;
}


/* -------HOME-2------------- */
.div-home-page-content-2{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
}
.div-minted{
  height: 107px;
  left: 0px;
  background: linear-gradient(269.93deg, #1D346F 22.11%, #111E41 82.34%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.title-minted-progress{
  font-family: 'ProximaNova';
  color: #92AFFF;
}
.progress-value{
  font-family: 'ProximaNova';
  color: #FFFFFF;
}
.progress-minted{
  width: 55%;
  height: 19px;
  background: #3B4560;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}
.progress-bar{
  background: linear-gradient(90deg, #F16500 1.48%, #FF8E3D 26%);
}
.div-home-page-2{
  padding: 100px 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
}
.rewards-divider {
  height: 2px;
  width: 35%;
}
.rewars-others {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px;
}
.col-img-rewards {
  padding: 0 30px;
  text-align: center;
  font-family: 'ProximaNova';
  color: #92AFFF;
  font-size: 20px;
}
.div-view-more-rewards{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.img-rewards {
  width: 345px;
  margin-bottom: 15px;
}
.div-other-rewards-images {
  padding: 40px 100px;
}
.div-view-more-rewards:hover .img-rewards{
  cursor: pointer;
  opacity: 0.2;
  z-index: 150;
}
.div-view-more-rewards:hover .view-more-rewards{
  opacity: 1!important;
  cursor: pointer;
}
.view-more-rewards{
  position: absolute;
  font-weight: bold;
  color: white;
  opacity: 0;
  z-index: 200;
}




/* --- */
.div-features-1{
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
}
.div-text-key-features-1{
  font-size: 16px;
  color: #FFFFFF;
  font-family: 'ProximaNova';
  font-weight: 600;
  position: static;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.div-text-key-features-1 span{
  display: block;
}
.div-text-keyfeatures{
  border-left: #F16500 13px solid;
  padding-left: 20px;
  margin-left: 8%;
}
.hr-features{
  position: absolute;
  width: 74%;
  color: white;
  border: 1px solid #92AFFF;
}
.hr-attributes{
  position: absolute;
  width: 74%;
  color: white;
  border: 1px solid #92AFFF;
}
.hr-attributes-title{
  position: absolute;
  width: 74%;
  color: white;
  border: 1px solid #92AFFF;
}
.col-3-others-rewards {
  padding: 0 30px;
}
.col-text-others-rewards {
  text-align: center;
  font-family: "ProximaNova";
  color: #92AFFF;
  font-size: 20px;
  margin-top: 20px;
}
.text-other-rewards {
  color: #FF8E3D;
  font-size: 22px;
  font-family: 'ProximaNova';
  font-weight: 700;
}
.col-6-others-rewards{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.view-more-other-rewards{
  position: absolute;
  font-weight: bold;
  color: white;
  opacity: 0;
  z-index: 99999;
}
.col-img-others-rewards{
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-content-view-more{
  display: flex;
  align-items: center;
  justify-content: center;

}
.div-content-view-more:hover .img-other-rewards{
  opacity: 0.2;
  cursor: pointer;
 }
.div-content-view-more:hover .view-more-rewards{
  opacity: 1!important;
  cursor: pointer;
 }
.col-img-others-rewards img{
  width: 65%;
}

.div-text-key-features-2{
  width: 50%;
  color: #92AFFF;
  position: absolute;
  font-family: 'ProximaNova';
  font-weight: bold;
  line-height: 79.3%;
  opacity: 0.05;
  font-size: 150px;
}
.div-incognito-team{
  height: 65%;
  position: relative;
  padding-top: 10%;
}
.div-features-2{
  height: 65%;
  position: relative;
  padding-top: 1%;

}
.div-features-content{
  padding-left: 150px;
  padding-right: 150px;
}
.div-card-feature{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.img-feature-card{
  width: 90px;
}
.info-feature-card{
  font-family: 'ProximaNova';
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FF8E3D;
}
.description-feature-card{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.5%;
  text-align: center;
  color: #FFFFFF;  
}
.description-rewards {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.5%;
  text-align: center;
  color: #FFFFFF;
  width: 60vw;
  margin: auto;
}
/* ------HOME_3----------------------- */
.div-home-page-content-3{
  padding-top: 200px;
  background: #121E3A;
  background: linear-gradient(to left, #080E1F 50%, #121E3A 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-home-page-3{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.div-world-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.world-img{
}
.world-title{
  width: 180px;
  background: #080D1B;
  border-radius: 20px;
  padding: 15px 20px;
  text-shadow: 2px 5px 4px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  color: #92AFFF;
  line-height: 80%;
  letter-spacing: 0.85em;
}
.world-description{
  text-align: center;
  width: 45%;
  color: #FFFFFF;
  margin-top: 50px;
  margin-bottom: 40px;
}
.div-world-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  background: #0F1A36;
  box-shadow: 0px 33px 27px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 350px;
  height: 50px;

}
.div-world-buttons .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary:focus:active, .btn-outline-primary.active, .open > .btn-outline-primary.dropdown-toggle{
  background-color: transparent!important;
}
.div-world-buttons .btn:hover, .btn:focus, .btn:active, .btn.active, .btn:active:focus, .btn:active:hover, .btn.active:focus, .btn.active:hover, .show > .btn.dropdown-toggle, .show > .btn.dropdown-toggle:focus, .show > .btn.dropdown-toggle:hover, .navbar .navbar-nav > a.btn:hover, .navbar .navbar-nav > a.btn:focus, .navbar .navbar-nav > a.btn:active, .navbar .navbar-nav > a.btn.active, .navbar .navbar-nav > a.btn:active:focus, .navbar .navbar-nav > a.btn:active:hover, .navbar .navbar-nav > a.btn.active:focus, .navbar .navbar-nav > a.btn.active:hover, .show > .navbar .navbar-nav > a.btn.dropdown-toggle, .show > .navbar .navbar-nav > a.btn.dropdown-toggle:focus, .show > .navbar .navbar-nav > a.btn.dropdown-toggle:hover{
  background-color: transparent!important;
}
.btn, .navbar .navbar-nav > a.btn {
  padding: 5px 00px;
}
.world-button{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  color: #A2ADCB;
  opacity: 0.5;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  background: none;
  border: none;
  width: 123px;
}
.world-button:hover{
  color: #92AFFF;
  font-weight: bold;
  opacity: 1;
  border-radius: 40px;
  background-color: transparent!important;
  color: #A2ADCB!important;
}
.btn-outline-primary {
  border: 0px!important;
}
.world-button .btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #A2ADCB!important;
  opacity: 1;
  background-color: transparent;
  border-radius: 40px!important;
  border: solid #92AFFF 3px!important;
}
/* ----------------------------- */
/* --------HOME-3-------------------- */
.div-home-page-content-4{
  padding: 30px 0 50px 0;
  background: linear-gradient(to left, #080E1F 50%, #121E3A 50%);
}
.div-carousel-imgs{
  width: 50%;
  height: 100%;

}
.div-img-carousel{
  height: 100%;
}
.div-img-carousel div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-base-carousel{
  width: 80%;
}
.div-carousel-info{
  width: 50%;
  display: flex;
  align-items: center;

}
.div-table-info-carousel{
  width: 55%;
  margin-left: 20%;
}
.icon-info-title-name{
  color: #FFFFFF;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
}
.icon-info-title{
  color: #FFFFFF;
  opacity: 0.3;
  font-size: 15px;
}
.icon-info{
  font-weight: bold;
  font-size: 14px;
  line-height: 147.38%;
  color: #FFFFFF;
}
.icon-info-rarity{
  font-weight: bold;
  font-size: 14px;
  line-height: 147.38%;
  color: #FF8E3D;
}
.icon-ETH-carousel{
  padding-right: 5px;
  font-size: 18px;
}
.btn-view-reward{
  font-size: 14px;
  font-weight: 600;
  padding: 15px 50px;
  border-radius: 8px;
  background: linear-gradient(180deg, #102356 -14.89%, #5A79CC 117.02%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  border-radius: 27px;
  margin: 0px 10px;
  font-family: 'ProximaNova'; 
}
.new-attributes-page{
  font-family: 'ProximaNova'; 
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  position: absolute;
  top: 37px;
  padding-left: 109px;
  color: #FF8E3D;
}

.roadmap-img {
  width: 150px;
}
.container-features-roadmap {
  background-color: #1C2847;
}
.div-features-roadmap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  padding-top: 120px;
  margin-bottom: 20px;
}
.container-range-roadmap {
  margin: 20px 0 35px 0
}
.img-timeline {
  max-width: 90%;
  margin: auto;
  display: flex;
}
.line-roadmap {
  z-index: 5;
  margin-bottom: -10px;
}
.container-roadmap {
  padding: 0 7vw 35px 7vw;
}
.box-empty-roadmap {
  width: 260px!important;
}
.box-roadmap {
  display: flex;
}
.info-roadmap {
  font-family: "ProximaNova";
  font-weight: 400;
  color: #FFFFFF;
  font-size: 14px;
  margin-left: 28px;
}
.date-roadmap {
  min-width: 150px;
  height: 150px;
  background-size: cover;
  background-image: url('../scss/img/roadmap.png');
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "ProximaNova";
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: #F16500;
}
.bems-scroller {
  display: flex;
  height: 200px;
  border-radius: 1em;
  overflow: hidden;
}
.bems-scroller #scrolling-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 1em 0;
  overflow-x: scroll;
  overflow-x: -moz-scrollbars-none;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.bems-scroller #scrolling-container::-webkit-scrollbar {
  display: none;
}
.bems-scroller #scrolling-container2 {
  display: flex;
  flex-wrap: nowrap;
  padding: 1em 0;
  overflow-x: scroll;
  overflow-x: -moz-scrollbars-none;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.bems-scroller #scrolling-container2::-webkit-scrollbar {
  display: none;
}
.bems-scroller li {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  list-style-type: none;
  width: 550px;
  margin: 0 1em;
}
.bems-scroller li img {
  border-radius: 0.5em 0.5em 0 0;
}
.bems-scroller .title {
  background-color: #fff;
  height: 50px;
  border-radius: 0 0 0.5em 0.5em;
}
.bems-scroller #button-scroll-left,
.bems-scroller #button-scroll-right {
  display: flex;
  padding: 2em;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 200px;
  width: 50px;
  background-color: #333;
}
.bems-scroller i {
  color: #ddd;
}
.rangeslider {
  margin: 0 auto;
  padding: 20px 0;
  width: 500px;
}

input[type="range"] {
  background: #1C2847;
  height: 10px;
  width: 90%;
  display: flex;
  margin: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 3px;
}
input[type="range"]:focus {
  outline: none;
}
.rangeslider,
input::-webkit-slider-thumb {
  position: relative;
  z-index: 15;
  background-image: url(./img/button-roadmap2.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #1a1a1a;
  cursor: pointer;
  text-indent: -9999px;
  width: 115px;
  height: 44px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.3), 0 1px 0 rgba(255,255,255,0.2) inset;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 50px;
}
.div-carousel-attributes{
  width: 100%;
  height: 100vh;
}
.img-attributes{
  width: 300px;
}
.description-attributes-card{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.5%;
  text-align: center;
  color: #FFFFFF;
  width: 260px;
}

/* -----TEAM INCOGNITO-------- */
.div-home-page-content-5{
  height: 100%;
  
  background: #0C1324;
}

.div-rewards-description {
  margin: 60px 0;
}
.div-rewards-images-container {
  background-color: #121E3A;
}
.div-rewards-images {
  padding: 80px 16vw;
}
.div-container-team{
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}
.card{
  box-shadow: 0 2px 2px rgb(0 0 0 / 50%)!important;
}
.div-table-team{
  width: 90%;
}

.div-img-team-facundo{
  background-image: url(./img/team-img/facundo.jpg);
}
.div-img-team-nicolas{
  background-image: url(./img/team-img/nicolas.jpg);
}
.div-img-team-emanuel{
  background-image: url(./img/team-img/emanuel.jpg);
}
.div-img-team-emanuel .div-card-body{
  margin-bottom: 40px;

}
.div-img-team-sam{
  background-image: url(./img/team-img/sam.jpg);
}
.div-img-team-agustin{
  background-image: url(./img/team-img/agustin.jpg);
}
.div-img-team-martha{
  background-image: url(./img/team-img/martha.jpg);
}
.div-img-team-leonardo{
  background-image: url(./img/team-img/leonardo.jpg);
}
.div-img-team-offradar{
  background-image: url(./img/team-img/offradar.jpg);
}
.div-img-team-charles{
  background-image: url(./img/team-img/charles.jpg);
}
.div-img-team-dynasty{
  background-image: url(./img/team-img/dynasty.jpg);
}
.div-img-team-spaceman{
  background-image: url(./img/team-img/spaceman.jpg);
}
.div-img-team-ishuai{
  background-image: url(./img/team-img/ishuai.png);
}

.div-img-team{
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.carousel-heigth {
  height: 680px;
}
.img-team{
  width: 50px;
  position: absolute;
  z-index: 50;
}
.div-card-body{
  background: #1C2847;
  color: white;
  height: 120px;
  width: 100%;
  display: flex;
  justify-content:flex-end;
  flex-direction: column;
  margin-bottom: 10px;
}

.div-card-body h5{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 20px;
}
.div-card-body p{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #92AFFF;
}
.div-card-body span{
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 17px;
  text-transform: uppercase;
  color: #92AFFF;
}
.div-card-body span:hover{
  text-decoration: underline;
  cursor: pointer;
}
/* ---------------------------*/
.wrapper {
  width: 100%;
  height: 100vh;
  background: #3498DB;
}


.card-team {
  height: 320px;
  width: 280px;
  background: #1C2847 center/cover!important;
  margin: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;

}
.card-team:hover .fon-team {
  opacity: 0;
}
.card-team:hover .text-team {
  height: 50%;
}
.card-team:hover .text-team h2 {
  margin-bottom: 0;
}
.card-team:hover .text-team p {
  opacity: 0;
}
.card-team:hover .btn-team {
  transform: scale(1);
}
.card-team:hover .card-title {
  transform: scale(0);
}
.card-team:hover .card-text {
  transform: scale(0);
}
.card-team:hover .img-team {
  transform: scale(0);
}

.fon-team {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  z-index: 2;
  transition: opacity 0.25s;
}

.text-team {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  height: 70%;
  width: 100%;
  text-align: center;
  z-index: 4;
  transition: height 0.25s, padding 0.25s;
}
.text-team h2 {
  text-transform: uppercase;
  color: #3498DB;
  letter-spacing: 2px;
  margin-bottom: 20px;
  transition: margin-bottom 0.25s;
}
.text-team p {
  line-height: 20px;
  margin: 0 10px;
  color: #777;
  z-index: 5;
  transition: opacity 0.25s;
}
.text-team .triangle-team {
  width: 100%;
  height: 50px;
  background: #fff;
  z-index: 9;
  transform: matrix(1.01, -0.3, 0, 2, -1, 10);
  z-index: 3;
}

.btn-team {
  position: absolute;
  top: calc(75% - 20px);
  left: calc(20% - 40px);
  margin: auto;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  background: transparent;
  z-index: 4;
  transform: scale(0);
  cursor: pointer;
  font-size: 14px;

}
.btn-team:hover{
  cursor: pointer;
}

/* ---------------------------*/
/* -----------MODAL MEMBER----------------*/
.downloading-spinner-container{
  position: absolute;
  background: rgba(0,0,0, 0.3);
  width: 100%;
  height: 100%;
  top: -1rem;
  display: flex;
}
.download-button{
  text-align: center;
  margin-top: 15px;
  font-weight: 900;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
}  
.member-icon-image{
  width: 500px;
  height: 500px;
}
.downloading-spinner-container .loader{
  width: 90px;
  height: 90px;
}
.member-sorting-select{
  background: #121E3A;
  border-color: #2D4783;
  border-radius: 24px;
  color: #FFF;
  margin-left: 20px;
}
.member-modal-please-wait{
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #FFF;
  margin-top: 15px;
}
.modal-member{
  height: 60%;
  width: 60%;
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
}
.modal-incgonito-img-section{
  background: #121E3A;
}

.modal-incognito-details{
  background: #1C2847;
  padding-bottom: 65px;
}
.div-close-modal-member{
  width: 100%;
  text-align: end;
}
.btn-close-modal-member{
  padding-top: 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  opacity: 0.8;
}
.btn-close-modal-member:hover{
  opacity: 1;
}
.div-modal-info-member{
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  padding: 20px 40px 40px 40px; 
}
.modal-memeber-data{
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 14px;
  border-bottom: 4px solid rgba(196, 196, 196, 0.04);
}
.member-moda-info-bold-text{
  font-weight: 700;
  color: #FFF;
  text-transform: uppercase;
}
.member-moda-info-text{
  color: #FFF;
}
.download-link{
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
}
.div-social-member-container{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.social-links-member{
  text-decoration: none;
}
.social-icon-member{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-size: 35px;
  color: white;
}
.text-info-modal-member{

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.member-modal-info-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-size: 14px;
  margin-top: 3rem;
  border-top: 4px solid rgba(196, 196, 196, 0.04);
  border-bottom: 4px solid rgba(196, 196, 196, 0.04);
}
.text-role-modal-member{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #92AFFF;
}
.text-description-modal-member{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172%;
  color: #FFFFFF;
}
/* ---------------------------*/
/* ----------FOOTER-----------------*/
.footer{
  height: 95px;
  background: #202020;
}

.div-footer-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 60px;
}
.div-reserved-incognito{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.div-routers-footer{
  display: flex;
}
.div-routers-footer a:hover, a:focus {
  display: block;
  color: white;
  background-color: transparent!important;
}
.social-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-size: 25px;
  color: white;
}
/* ---------------------------*/
/* -----------MODAL REWARDS----------------*/
.modal-rewards{
  width: 25%;
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  overflow: overlay;
  position: fixed;
  flex-direction: column;
  height: 90%;
  background: #1C2847;
}
::-webkit-scrollbar{
  display: none;
}
.div-modal-reward-info-content{
  padding: 40px;
}
.reward-modal-name{
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.reward-modal-info{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  
  text-transform: uppercase;

  color: #92AFFF;
}
.reward-modal-description{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172%;
  color: #FFFFFF;
}
.btn-close-modal-rewards{
  top: 2%!important;
  right: 5%!important;
  
}
.btn-close-modal-rewards i{
  font-size: 30px;
  opacity: 1;
}
/* ---------------------------*/
/* -----------ATRIBUTES----------------*/
.div-atributes-container{
  width: 100%;
}
.div-img-atributes{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-img-atributes img{
  width: 85%;
}
.div-description-atributes{
  width: 50%;
  padding: 40px;
  display: flex;
  align-items: center;
}
.div-description-atributes-row{
  padding: 10%;

}
/* ---------------------------*/
.main-panel > .footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer ul li a {
  text-transform: none;
}



.footer .heart {
    color: #EB5E28;
}

footer nav ul a:not(.btn) {
    color: #66615b;
    display: block;
    margin-bottom: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  border-radius: 6px;
  box-shadow: 0 2px 2px rgb(204 197 185 / 50%);
  background-color: #FFFFFF;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;

  .content {
    padding: 15px 15px 10px 15px;
  }

  .numbers {
    font-size: 2em;
    text-align: right;
  }

  .stats {
    color: #a9a9a9;
    font-weight: 300;
    font-size: 14px;
  }
  .icon-big {
    font-size: 3em;
    min-height: 64px;
  }
  .numbers p {
    font-size: 16px;
  }
}

.card-ticket {
  flex: 1 0 15% !important; /* explanation below */
}

.nft_category {
  padding: 5px 20px;
  border: 1px solid;
  border-radius: 15px;
  font-weight: 900;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
}

.art {
  border-color: red;
  background-color: red;
}
.sport {
  border-color: green;
  background-color: green;
}
.meme {
  border-color: #FF9800;
  background-color: #FF9800;
}
.crypto {
  border-color: lightgray;
  background-color: lightgray;
}
.btn-disconect:hover {
  color: #dc3545!important;
}
.tokenPriceContainer{
  padding: 5px 20px;
  border: 1px solid;
  border-radius: 3px;
}
.cursor-pointer{
  cursor: pointer;
}
.div-connect-to-wallet{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-connect-wallet {
  text-transform: inherit;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 8px;
  background: linear-gradient(180deg, #102356 -14.89%, #5A79CC 117.02%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  border-radius: 27px;
  margin: 0px 10px;
  font-family: 'ProximaNova';
}

.btn-fullRoadmap {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 27px;
  margin-top: 60px;
  margin-bottom: 60px;
  font-family: 'ProximaNova';
  background-color: #F16500;
}

.div-sign-out-icon{
  cursor: pointer;
  background: #FF8E3D;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-out-icon{
  color: #FFFFFF;
  font-size: 24px;
  padding: 10px 12px;
}
.btn-connect-wallet-address {
  padding: 5px 10px;
  border-radius: 6px;
  margin-right: 10px;
  margin-left: -12px;
}

.amount-ntf {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}
.button-mint {
  border-radius: 5px;
  border: none;
  background: #51bcda;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mint-button-icon {
  font-size: 18px;
  cursor: pointer;
  color: #FF6B00;
}
/* GALLERY */
a {
  text-decoration: transparent;

}

.div-title-gallery{
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  margin-top: 140px;
  margin-bottom: 40px;
}
.div-content-gallery{
  padding: 100px;
}
.div-content-gallery-filter{
  width: 25%;
 
}
.div-accordion-content{
  padding-top: 10px;
  width: 250px;
  background: #121E3A;
  min-height: 500px;
  border-radius: 10px;
  font-family: 'ProximaNova';
  padding-bottom: 20px;
}
.accordion-item-gallery{
  border-left: 3px solid #F16500;
  margin-top: 20px;
}
.accordion-item-gallery button{
  color: #92AFFF;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2379808F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2379808F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed){
  background-color: transparent;
  color: #92AFFF;
}
.div-content-gallery-filter .accordion-button{
  border: 0px;
  padding: 5px 20px;

}
.div-content-gallery-filter .accordion-button:focus{
  box-shadow: none;
}
.input-filter-material{
  background: #121E3A;
  border: 1px solid #2D4783;
  box-sizing: border-box;
  border-radius: 3px;
  color: white;
  font-family: 'ProximaNova';
}
.input-filter-material::placeholder{
  color: #42527A;
  padding-left: 5px;
  font-family: 'ProximaNova';
  font-size: 13px;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.accordion-item-gallery .accordion-body{
  display: flex;
  flex-direction: column;
}
.div-filters-checks{
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.div-filters-checks input{
  width: 26px;
  height: 26px;
  border-radius: 3px;
}
.div-filters-checks input:hover{
  cursor: pointer;
}
.div-filters-checks label:hover{
  cursor: pointer;
}
.div-filters-checks label{
  color: white;
  padding-left: 10px;  
  font-size: 14px;
  font-family: 'ProximaNova';
}

.div-content-gallery-cards{
  width: 75%;
}

.div-order-cards{
  display: flex;
  justify-content: space-between;
}
.select-sort-nfts{
  background: #121E3A;
  border: 1px solid #2D4783;
  box-sizing: border-box;
  border-radius: 24px;
  color: white;
  width: 219px;
  text-transform: uppercase;
  padding: 7px 5px;
  margin-left: 20px;  
  font-family: 'ProximaNova';
}
.text-sort{
  color: #FFF;
  font-size: 18px;
  font-family: 'ProximaNova';
}
.showing-icons-number{
  color: #92AFFF;
  font-size: 18px;
  font-family: 'ProximaNova';
}
.div-content-nfts-cards .card{
  background: linear-gradient(180deg, #121E3A 55%, transparent 45%);
  box-shadow: none!important;
  color: white;
}
.div-buttons-search{
  width: 100%;
  display: flex;
  justify-content:center;
}
.buttonSearch{
  background: #121E3A;
  border: 1px solid #2D4783;
  box-sizing: border-box;
  border-radius: 24px;
  color: white;
  padding: 3px 8px;
  font-family: 'ProximaNova';
}
.buttonSearch i{
  margin-left: 5px;
  font-size: 13px;
}
.button-page{
  background: #121E3A;
  border: 1px solid #2D4783;
  box-sizing: border-box;
  border-radius: 50%;
  color: white;
  font-size: 1.5rem;
  padding: 0px 10px;
}
.number-page{
  color: #92AFFF;
  font-size: 1.2rem;
  margin-left: 10px;
  margin-right: 10px;
}
.number-page:hover{
  cursor: pointer;


}
.number-page-select{
  font-weight: bold;
  color: white!important;
  font-size: 1.2rem;
  margin-left: 10px;
  margin-right: 10px;
}
.div-alert-no-coincidences{
  width: 100%;
  text-align: center;
}
.div-alert-no-coincidences h1{
  font-family: 'ProximaNova';
  color: #92AFFF;
  opacity: 0.4;
}
/* ----- */
/* LOADER */
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  opacity: 0.9;
}
.loader-material{
  margin: auto;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  opacity: 0.9;
}
.div-loading{ 
  text-align: center;
  font-size: 2rem;
  color: white;
  font-family: 'ProximaNova';
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* ----- */

/* ATTRIBUTES */
.div-attributes-content{
  padding-bottom: 50px;
}
.div-btn-group-attributes-content{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 110px 0px;
}
.div-btn-group-attributes{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  background: #0F1A36;
  box-shadow: 0px 33px 27px rgb(0 0 0 / 25%);
  border-radius: 40px;
  width: 530px;
  height: 65px;
}
.div-content-types-materials{
  width: 80%;
  margin: auto;

}
.title-icons-materials{
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 40px;
  color: #FF8E3D;
  font-family: 'ProximaNova';
}
.table-attributes-content{
  color: white; 
}
.tr-title-attributes{
  height: 63px;
}
.th-title-attributes{
  text-transform: none!important;
  font-style: normal;
  font-weight: bold!important;
  font-size: 18px!important;
  line-height: 24px;
  font-family: 'ProximaNova';
  color: #FFFFFF;
  padding-top: 25px!important;
  padding-bottom: 30px!important;
}
.div-attributes-content .table>:not(caption)>*>*{
  border-bottom: 0px;
}
.tr-info-attributes{
  height: 63px;
}
.attribute-table{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #92AFFF;
  font-family: 'ProximaNova';
  padding-left: 25px!important;
}
.tr-info-attributes-background1{
  background-color: transparent;
}
.tr-info-attributes-background2{
  background-color: #121E3A;
}
.info-table-td{
  text-align: end;
}

.div-content-attributes{
  margin-top: 115px;
}
.th-title-attributes{
  text-align: end;
}
.th-attribute{
  text-align: start!important;
  width: 210px;
  padding-left: 25px!important;
}
.tr-info-rarity {
  height: 55px;
}
.info-table-td-rarity{
  text-align: start;
}
.th-number-rarity{
  text-align: start!important;
  padding-left: 25px!important;
}
.th-id-incognito{
  text-align: start!important;
}
.th-available-rarity{
  text-align: start!important;
}
.th-cheapest-rarity{
  text-align: start!important;
  padding-left: 50px!important;
}
.td-info-cheapest-rarity{
  padding-bottom: 0px!important;
  padding-top: 0px!important;
  text-align: start!important;
  padding-left: 50px!important;
}

.th-amount{
  width: 130px;
}
.th-available{
  width: 160px;
}
.td-info-cheapest{
  padding-bottom: 0px!important;
  padding-top: 0px!important;
  text-align: start!important;
  padding-left: 50px!important;
}
.td-img-cheapest{
  width: 55px;
  margin-right: 15px;
  background-color: #C1C1C1;
}
.td-img-cheapest-rarity{
  width: 50px;
  margin-right: 15px;
  background-color: #C1C1C1;
}
.td-img-icon-examples{
  width: 55px;
  margin-right: 15px;
  background-color: #C1C1C1;
}
.th-cheapest{
  width: 190px;
  text-align: start!important;
  padding-left: 50px!important;
}
.icon-eth-cheapest{
  margin-right: 5px;
}
.th-icon-examples{
  text-align: start;
  padding-left: 55px!important;
}
.td-info-icons-examples{
  display: flex;
  justify-content: space-between;
  padding: 0px 5px 0px 55px!important;
  height: 63px;
  align-items: center;
}

.div-content-attributes-count{
  margin-top: 115px;
}

.div-key-content{
  width: 80%;
  margin: auto;
  margin-top: 115px;
}
.key-title{
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  font-family: 'ProximaNova';
  color: #FF8E3D;
  margin-bottom: 40px;
}
.div-content-key-info{
  display: flex;
  justify-content: space-between;
}
.box-key{
  
}
.key-subtitle{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  font-family: 'ProximaNova';
  color: #92AFFF;
}
.key-info{
  width: 225px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  font-family: 'ProximaNova';
  color: #FFFFFF;
}

.div-incognito-stats-content{
  width: 80%;
  margin: auto;
  margin-top: 115px;
}
.incognito-stats-title{
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  font-family: 'ProximaNova';
  color: #FF8E3D;
  margin-bottom: 40px;
}
.div-pre-sale-content{
  margin-bottom: 60px;
}
.div-pre-sale-1{
  display: flex;
}
.div-pre-sale-2{
  display: flex;
}
.div-subtitle-pre-sale{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #92AFFF;
  width: 200px;
}
.div-info-pre-sale{
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}
.div-info-time-pre-sale{
  display: flex;
  flex-direction: row;
  padding: 3px 6px;
  height: 23px;
  background: #F16500;
  border-radius: 5px;
  margin-left: 15px;
  line-height: 18px;
}
.div-content-attribute-responsive{
  border-bottom: 1px solid #92AFFF;
  padding-bottom: 10px;
}
.title-attribute-responsive{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: 'ProximaNova';
}
.info-attribute-responsive-color{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #92AFFF;
  font-family: 'ProximaNova';
}
.info-attribute-responsive{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.div-cheapest-responsive{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  font-family: 'ProximaNova';
}
.img-cheapest-responsive{
  width: 55px;
  background-color: #C1C1C1;
}
.icon-eth-cheapest-responsive{
  margin-left: 20px;
  margin-right: 5px;
  color: #FFF;
}
.div-img-icon-responsive{
  display: flex;
}
.img-icon-examples-responsive{
  width: 55px;
  margin-right: 5px;
  background-color: #C1C1C1;
}
.div-circle-content{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.circle1{
  position: absolute!important;
  z-index: 605;
  height: 134px!important;
  width: 134px!important;
  top: 23px;
  left: 25px;
}
.circle2{
  top: 0px;
  position: absolute!important;
  z-index: 600;
  left: 0px;
}
.col-circle-progress{
  position: relative;
}
.img-logo-attributes{
  position: absolute;
  top: 50px;
  left: 69px;
}
.div-circle-progress-content{
  width: 80%;
  margin: auto;
  margin-top: 115px;
  min-height: 185px;
}
.info-circle-progress{
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 42%;
}
.title-circle-progress{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #92AFFF;
  font-family: 'ProximaNova';
  display: flex;
  align-items: center;
}
.box-info-circle-progress-1{
  
}
.box-info-circle-progress-2{}
.box-color-max-supply{
  width: 15px;
  height: 15px;
  background-color: #3E5794;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  margin-right: 5px;
}
.box-color-icon-holders{
  width: 15px;
  height: 15px;
  background-color: #92AFFF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  margin-right: 5px;
}
.circle-progress-info{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #FFFFFF;
  font-family: 'ProximaNova';
  padding-left: 50px;
}
.col-highest-sale-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.highest-sale-content{
  width: 529px;
  height: 121px;

  background: #121E3A;
  border-radius: 10px;
  padding: 15px 25px;
}
.title-highest-sale{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #FF8E3D;
  border-bottom:  1px dashed #FFFFFF;
  padding-bottom: 10  px;
  margin-bottom: 10px;
}
.img-highest-sale{
  width: 55px;
}
.icon-highest-sale{
  color: #FFFFFF;
  font-size: 2rem;
}
.name-highest-sale{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 23px; 
  color: #FFFFFF;
  text-transform: uppercase;
  width: 135px;
}
.highest-sale-number{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  color: #FFFFFF;
}
.etherscan-button{
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #92AFFF;
  background: transparent;
  border: 2px solid #92AFFF;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 10px 20px;
}
.etherscan-button-responsive{
  display: none;
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #92AFFF;
  background: transparent;
  border: 2px solid #92AFFF;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 10px 20px;
}
.box-transactions{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #92AFFF;
  font-family: 'ProximaNova';
  margin-top: 110px;
  width: 80%;
  margin: 40px auto;
}
.number-transactions{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  font-family: 'ProximaNova';
  margin-left: 20px;
}
.title-attribute-table {
  font-size: 18px;
  margin: 10px 0px;
  font-weight: bold;
  color: #92AFFF;
  padding-left: 25px;
  font-family: "ProximaNova";
}
.title-attribute-table::first-letter {
  text-transform: uppercase;
}
.highest-sale-content a:hover, a:focus {
  color: #92AFFF!important;
}
.div-attributes-content .div-routers-footer a:hover, a:focus {
  display: contents!important;
}

.div-content-button-attributes a:hover, a:focus {
  color: #FF8E3D;
}

/* ------------ */
@media (max-width: 1550px) {
  .td-img-icon-examples{
    margin-right: 0px;
  }
} 
@media (max-width: 1450px) {
  .th-amount {
    width: 110px;
  }
  .th-available {
    width: 110px;
  
  }
  
} 
@media (max-width: 1400px) {
  .th-icon-examples {
    padding-left: 25px !important;
  }
  .td-info-icons-examples {
    padding: 0px 10px 0px 25px !important;
  }
  .th-cheapest {
    padding-left: 35px !important;
    width: 175px;
  }
  .td-info-cheapest {
    padding-left: 35px !important;
  }


  
} 
@media (max-width: 1300px) {
  .th-attribute {
    width: 160px;
  }
  .td-info-icons-examples{
    height: 71px;
  }
  .th-icon-examples {
    padding-left: 15px !important;
  }
  .td-info-icons-examples {
    padding: 0px 10px 0px 15px !important;
  }
  
  .div-content-gallery {
    padding: 50px;
  }
} 
@media (max-width: 1250px) {
  .circle-progress-info {
    padding-left: 10px;
  } 
  .th-amount {
    width: 102px;
  }
}
@media (max-width: 1200px) {
  .col-circle-progress {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }
  .info-circle-progress {
    height: auto;
    padding-left: 0px;
    margin-left: 40px;
  }
  .circle1{
  }
  .circle2{
    position: relative!important;
  }
  .box-key{
    margin-top: 40px;
  }
}
@media (max-width: 992px) { 
  /* NAVBAR */
  .nav-navbar-content{
    
    flex-direction: row;
    padding-top: 10px!important;
  }
  /* -- */
  .div-home-content-1{
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .div-home-page-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .div-home-page-content-1 {
    height: 90vh;
  }
  .div-text-key-features-2{
    display: none;
  }
  .hr-features{
    display: none;
  }
  .div-img-home{
    padding: 0px!important;
  }
  .div-incognito-home{
    width: 100%;
  }
  .div-home-img-2 img{
    width: 360px;

  }
  .div-img-home{
    width: 100%;
  }
  .div-img-home img{
    width: 50%;
  }
  .div-home-text-3{
    width: 100%;
    margin-bottom: 0px!important;
  }
  /* FOOTER */
  .item-navbar{
    display: none;
  }
  /* ------ */
  .carousel-heigth{
    flex-direction: column;
  }
  .div-carousel-imgs{
    width: 100%;
    height: auto;
    margin-top: 10%;
  }
  .div-img-carousel{
    height: auto;
  }
  .div-img-carousel div{
    height: auto;
  }
  .img-base-carousel{
    width: 45%;
  }
  .div-carousel-info{
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .div-table-info-carousel{
    margin: 0px;
  }
  /* GALLERY */
  .div-card-content{
    padding: 30px;
  }
  .div-title-gallery{
    margin: 0px;
  }
  .div-content-gallery {
    padding: 10px;
  }
  .div-accordion-content {
    width: 210px;
  }


  /* ------- */
}
@media (max-width: 768px) { 
  .div-home-text-3{
    font-size: 14px;
    margin-bottom: 40px!important;
  }
  /* GALLERY */
  .div-content-gallery {
    justify-content: space-between;
  }
  .div-order-cards{
    flex-direction: column;
  }
  .div-content-gallery-cards {
    width: 65%;
  }
  .div-input-sort{
    margin-bottom: 20px;
  }
  /* -------- */
  /* GO INCOGNITO */
  .modal-incognito-details{
    padding-bottom: 0px;
  }
  .modal-memeber-data{
    font-size: 13px;
  }
  .member-modal-info-container {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
  }
  .your-icons{
    font-size: 12px;
  }
  .div-row-nft-cards{
    padding-right: 20px;
    padding-left: 20px;
  }
  .div-row-nft-cards .card{
    background: linear-gradient(180deg, #121E3A 50%, transparent 45%);
  }
  /* ------------ */
  .info-feature-card{
    margin-top: 0px!important;
    width: 160px;
  }
  .div-card-feature{
    margin-top: 20px;
  }
  .carousel-heigth{
    height: auto;
  }
  .div-table-info-carousel{
    width: 60%;
  }
  .div-home-page-content-3{
    padding-top: 60px;
  }
  .icon-info-title-name{
    font-size: 20px;
  }
  .icon-info-title{
    width: 100%!important;
  }
  .icon-info-title-responsive{
    margin-top: 10px!important;
  }
  .icon-info-rarity{
    width: 100%;
    margin-top: 5px!important;
  } 
  .icon-info{
    width: 100%;
    margin-top: 5px!important;
  }
  /* -ATTRIBUTES- */
  .div-routers-footer a:hover, a:focus {
    display: block!important;
  }
  .div-atributes-container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .div-img-atributes{
    width: 100%;
  }
  .div-img-atributes img {
    width: 60%;
  }
  .div-description-atributes{
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .div-description-atributes-row{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  /* -ROADMAP- */
  .div-features-roadmap {
    padding-top: 50px;
  }
  .div-container-team {
    margin-top: 50px;
  }
  /* -MODAL MEMBER- */
  .card-team-container{
    width: 100%;
  }
  .modal-member {
    flex-direction: column;
    height: auto;
    width: 70%;
  }
  
  .div-modal-info-container-member{
    width: 100%;
  }
  .text-description-modal-member{
    margin-top: 10px!important;
  }
  .div-close-modal-member{
    right: 4%;
    position: absolute;
    top: 4%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .div-close-modal-member i{
    font-size: 40px;
  }
  /* -REWARDS- */
  .div-home-page-2{
    padding: 10px;
  }
  .description-rewards{
    font-size: 20px;
    width: 90vw;
  }
  .div-rewards-description{
    margin-top: 20px;
  }

  .modal-rewards{
    width: 70%;
    height: 90%;
  }
  .reward-modal-description {
    font-size: 20px;
  }
  .div-rewards-images {
    padding: 30px 7vw;
  }
  .div-other-rewards-images {
    padding: 20px;
  }
  .member-icon-image{
    width: 300px;
    height: 300px;
  }
  .hr-attributes-title {
    width: 62%;
  }

}
@media (max-width: 576px) {
  .highest-sale-number{
    font-size: 34px;
  }
  .name-highest-sale{
    text-align: center;
  }
  .col-highest-sale-content{
    padding: 0px;
  }
  .div-btn-group-attributes-content {
    margin: 80px 0px; 
  }
  .key-title {
    margin-bottom: 5px;
  }
  .div-key-content {
    width: 95%;
  }
  .box-transactions{
    width: 95%;
  }
  .highest-sale-content {
    height: 175px;
  }
  .col-circle-progress{
    padding: 0px!important;
    margin-bottom: 35px;
  }
  .circle-progress-info {
    font-size: 12px;
    padding-left: 5px;
    text-align: end;
  }
  .title-circle-progress {
    font-size: 12px;
  }
  .info-circle-progress {
    margin-left: 15px;
  }
  .div-incognito-stats-content {
    width: 95%;
    margin-top: 80px;
  }
  .hr-attributes{
    width: 95%!important;
  }
  .hr-attributes-title{
    width: 49%!important;
  }
  .div-title-gallery{
    padding-left: 0px;
  }
  .div-circle-progress-content {
    width: 95%;
    margin-top: 100px;
  }
  .etherscan-button{
    display: none;
  }
  .etherscan-button-responsive{
    display: block;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }
  .div-content-types-materials{
    width: 95%;
  }
  .div-pre-sale-1{
    display: block;
  }
  .div-pre-sale-2{
    display: block;
  }
  .div-subtitle-pre-sale{
    margin-bottom: 0px!important;
  }
  .circle1 {
    height: 146px !important;
    width: 108px !important;
    top: 20px;
  }
  .circle2 {
      width: 150px!important;
      height: 150px!important;
  }
  .img-logo-attributes {
    top: 36px;
    left: 55px;
  }
  .hr-attributes-title {
    width: 39%;
  }
  .div-btn-group-attributes {
    width: 340px;
  }
  .div-img-icon-responsive{
    justify-content: space-between;
  }
  .img-icon-examples-responsive{
    width: 61px;
    margin-right: 0px;
  }
  .modal-incognito-details{
    padding-bottom: 0px;
  }
  .modal-memeber-data{
    font-size: 13px;
  }
  .member-modal-info-container {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
  }
  .username{
    font-size: 14px;
  }
  .your-icons{
    font-size: 12px;
  }
  /* GALLERY */
  .div-gallery-content{
    padding-top: 2rem!important;
  }
  .div-content-gallery{
    flex-direction: column;
    margin-top: 15px;
  }
  .div-content-gallery-filter {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .div-accordion-content {
    width: 90%;
    min-height: auto;
    padding-bottom: 30px;
  }
  .div-content-gallery-cards {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .div-order-cards{
    margin-top: 40px;
  }
  .div-input-sort{
  }
  .div-input-sort select{
    width: 190px;
  }
  .showing-icons-number{
    text-align: center;
  }
  /* -------- */
  /* GO INCOGNITO */
  .div-modal-info-container-member {
    height: 100%;
  }
  /* ----------- */
  .div-home-page-content-1 {
    height: 86vh;
  } 
  .div-home-img-2{
    width: 250px;
  }
  .div-img-home {
    align-items: flex-start;
  }
  .div-features-content{
    padding: 0px;
  }
  .world-description{
    width: 90%;
  }
  .div-img-home img{
    width: 65%;
  }
  .description-feature-card{
    padding-left: 40%;
    padding-right: 40%;
  }
  /* -ATTRIBUTES- */
  .div-img-atributes img {
    width: 80%;
  }
  /* -ROADMAP- */
  .div-container-team {
    margin-top: 20px;
  }
  .btn-fullRoadmap{
    width: 50%!important;
  }
  /* -MODAL MEMBER- */
  .modal-member {
    overflow: overlay;
    height: 80%;
    flex-direction: column!important;
  }
  .div-modal-info-member{
    padding: 20px 20px 10px 20px;

  }
  .div-social-member-container{
    justify-content: space-around;
  }
  .div-social-member-container a{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-icon-member{
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .div-close-modal-member{
    right: 1%;
    position: absolute;
    top: 4%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .div-close-modal-member i{
    font-size: 30px;
  }
  /* -REWARDS- */
  .div-home-page-2{
    padding-top: 40px;
  }
  .div-text-key-features-1{
    margin-bottom: 0px;
  }
  .description-rewards{
    font-size: 16px;
  }
  .div-rewards-description{
    margin-bottom: 20px;
  }
  .text-other-rewards{
    text-align: center;
  }
  .reward-modal-description {
    margin-top: 10px!important;
    font-size: 16px;
  }
  .div-rewards-images {
  }
  .col-img-rewards{
    width: 100%;
    margin-bottom: 40px;
  }
  .div-content-other-rewards{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .div-other-rewards-images {
  }
  .div-modal-reward-info-content{
    padding: 20px 10px;
  }
  .col-6-others-rewards{
    margin: 0px 0px 40px 0px!important;
    width: 100%;
  }
  .col-text-others-rewards{
    margin-top: 5px;
  }
}

.social-links {
  text-decoration: none;
}

