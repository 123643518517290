<script>
import CircleProgress from "vue3-circle-progress";
export default {
  name: 'CircleProgressComponent' ,
  components:{CircleProgress},
	props: ['iconHolders'],
  data() {
    return {
    }
  },

	computed: {
  },
	created() {
  },
}
</script>
<template>
  <div class="div-circle-content">
      <img src="../scss/img/incognito-logo-attributes.png" class="img-logo-attributes">
     <circle-progress class="circle1"
      :is-gradient="true" 
      :gradient="{
          startColor: '#92AFFF',
          stopColor: '#92AFFF',
      }"
      :percent="(this.iconHolders*100)/10000"
      empty-color="#3E5794"
      :border-width="20"
      :border-bg-width="0"
    
    />
     <circle-progress class="circle2"
      :is-gradient="true" 
      :gradient="{
          startColor: '#92AFFF',
          stopColor: '#92AFFF',
      }"
      :percent="40"
      empty-color="#3E5794"
      :border-width="0"
      :border-bg-width="30"
    
    />
  </div>
</template>
<style>
.div-circle-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>