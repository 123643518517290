<template>

<nav class="navbar navbar-expand-lg navbar-absolute fixed-top nav-navbar-content">
  <div class="d-none d-lg-flex media-bar w-100">
    <p style="color: #ffffff;"> Follow us on Twitter & join the Discord server! </p>
    <p class="w-35"> </p>
    <a href="https://twitter.com/IncognitoNFT" target="_blank" class="social-links"><i class="fab fa-twitter social-icon"></i></a>
    <a href="https://discord.gg/njnzd4gZwx" target="_blank" class="social-links"><i class="fab fa-discord social-icon"></i></a>
    <a href="https://www.instagram.com/incognitonft" target="_blank" class="social-links"><i class="fab fa-instagram social-icon"></i></a>
  </div>
  <div class="container-fluid px-5">
    
    
    <router-link to="/">
      <img src="../scss/img/incognito-logo.png" alt="">
    </router-link>

    <button type="button"  class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-bar bar1"></span>
      <span class="navbar-toggler-bar bar2"></span>
      <span class="navbar-toggler-bar bar3"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mx-auto">

        <li class="nav-item ">
          <router-link to="/" class="nav-link m-3 topbar-link">HOME</router-link>
        </li>
        <li class="nav-item ">
          <router-link to="/rewards" class="nav-link m-3 topbar-link">REWARDS</router-link>          
        </li>
        <li class="nav-item ">
          <a href="https://whitepaper.incognitonft.com/" class="nav-link m-3 topbar-link" target="_blank">WHITEPAPER</a>
        </li>
        <li class="nav-item ">
          <router-link to="/gallery" class="nav-link m-3 topbar-link">GALLERY</router-link>
          
        </li>
        <li class="nav-item ">
          <a href="/#roadmap" class="nav-link m-3 topbar-link">ROADMAP</a>
        </li>
        <li class="nav-item ">
          <a href="/attributes" class="nav-link m-3 topbar-link">ATTRIBUTES</a>
        </li>
        
        <li class="nav-item ">
          <span class="d-none d-md-block separator">|</span>
          <router-link to="/go-incognito" :class="`nav-link m-3 topbar-link ${(!wallet || !collectable.userNFTs || !collectable.userNFTs.length || collectable.gettingUserNFTs) && 'tooltip'}`">
            GO INCOGNITO
            <span v-if="!wallet" class="tooltiptext">Connect your wallet</span>
            <span v-else-if="collectable.gettingUserNFTs" class="tooltiptext">Getting your Icons...</span>
            <span v-else-if="!collectable.userNFTs || !collectable.userNFTs.length" class="tooltiptext">You don't have any Incognito</span>
          </router-link>
          
        </li>
      </ul>

      <div class="div-connect-to-wallet">
        <div v-if="!wallet">
          <button type="button" class="btn btn-connect-wallet" @click="showModal">
            CONNECT WALLET
          </button>
        </div>
        <div v-else class="d-flex">
          <button type="button" class="btn btn-connect-wallet">
            <span class="btn-connect-wallet-address">{{ walletShort }}</span>
          </button>
          <div class="div-sign-out-icon" @click="disconect">
            <i class="fas fa-sign-out-alt sign-out-icon"></i>
          </div>
        </div>
      </div>

    </div>
    <Modal v-show="isModalVisible" @close="closeModal" @openWallet="openWallet" />
  </div>
</nav>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import walletService from '../services/wallet';
import Modal from './WalletsModal.vue'

export default {
  name: 'TopBar',
  components: {
    Modal,
  },
  data() {
    return {
      isModalVisible: false,
      nameWallet: "",
    }
  },
  methods: {
    ...mapActions('wallet', ['setWallet', 'disconectWallet']),
    ...mapActions('collectable', ['isTheOwner', 'getSaleState', 'getPreSaleState', 'setTotalSupply', 'getBaseURI', 'getOwner', 'getProvenanceHash']),
    ...mapMutations('collectable', ['emptyCollection']),
    async openWallet(wallet) {
      this.isModalVisible = false
      this.nameWallet = wallet
      let address = await walletService.connect(wallet);
      await this.setWallet({ address });
      await this.getSaleState({})
      await this.getPreSaleState({})
      await this.getOwner({})
      await this.getBaseURI({});
      await this.getProvenanceHash({})
      await this.isTheOwner({})
      await this.setTotalSupply({})
    },
    async disconect(){
      if(this.nameWallet === "walletConnect"){
        await walletService.disconnectedWalletConnect();
      }
      if(this.nameWallet === "coinbase"){
        await walletService.disconnectedCoinbaseWallet();
      }
      let address = ''
      this.disconectWallet({address})
      this.emptyCollection()
      if(this.$route.path === '/go-incognito'){
        this.$router.push('/')
      }      
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  computed: {
    ...mapState('wallet', ['wallet']),
    ...mapState('collectable', ['collectable', 'errorMsg']),
    walletShort() {
      const prefix = this.wallet && this.wallet.toString().slice(0, 6).toUpperCase();
      const length = this.wallet && this.wallet.toString().length;
      const suffix = this.wallet && this.wallet.toString().slice(length-4, length).toUpperCase();
      return `${prefix}...${suffix}`;
    }
  },
}
</script>

<style scoped>
.topbar-link{  
  opacity: 0.5;
}
.topbar-link:hover{
  opacity: 1!important;
  color: #FFF!important
}
.separator{
  position: absolute;
  top: 52%;
}
.router-link-active{
  opacity: 1!important
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #080e1f;
  color: #7eb5f3;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.gallery-tooltip{
  left: -15px!important;
}

.media-bar{
  align-self: flex-end;
  padding-right: 4rem!important;
  justify-content: flex-end;
  background-color: #000;
}
</style>
